import type {ReactElement} from 'react';
import React from 'react';
import {Dropdown} from '@Components/dropdown-v2';
import {ALIGNMENT_TYPE} from '@Components/dropdown-v2/dropdown.types';
import type {TabClickHandler, TabItemContentStorage, TabItems, TabKey, TabsMoreOptionsConfig} from '@Components/tabs';
import { TabContentLayout} from '@Components/tabs';
import {TabItemContent} from '@Components/tabs/components/tab-item-content';
import {Icon} from '@Components/icon-v2';
import {IconSize} from '@Components/icon-v2/icon.types';
import {Text, TextSize} from '@Components/text';
import {ClickableDiv} from '@Components/clickable-div';
import {noop} from '@Utils/general.util';
import styles from './more-tabs-dropdown.module.scss';

interface MoreTabsDropdownProps {
  tabItems: TabItems;
  selectedTabKey: TabKey;
  dropdownSelectorConfig?: TabsMoreOptionsConfig;
  onTabItemClick?: TabClickHandler;
}

export function MoreTabsDropdown({tabItems, selectedTabKey, dropdownSelectorConfig, onTabItemClick = noop}: MoreTabsDropdownProps): ReactElement {
  const getMoreOptionsItem = (): ReactElement => {
    const isItemInsideDropdownSelected =
      tabItems.find((item) => {
        return item.tabKey === selectedTabKey;
      }) !== undefined;

    const colorClass = isItemInsideDropdownSelected ? 'content-body' : 'content-sub-text';

    return (
      <TabItemContent
        isSelected={isItemInsideDropdownSelected}
        tabKey="more"
        text={window.i18next.t('pmwjs_more')}
        contentLayoutType={TabContentLayout.FLEX}
        disableSelection
        className={`flex-row-align-center ${dropdownSelectorConfig?.className ?? ''}`}
        textClassName={dropdownSelectorConfig?.textClasses}
        preTitleContent={<Icon icon="icon-more-h" className={`spacing-m-r-1 ${colorClass}`} size={dropdownSelectorConfig?.iconSize ?? IconSize.SIZE_ICON_16} />}
      />
    );
  };

  const getClassForItem = (item: TabItemContentStorage): string => {
    if (item.disabled) {
      return styles.disabled;
    }

    if (item.tabKey === selectedTabKey) {
      return styles.selectedItem;
    }

    return styles.itemUnselected;
  };

  const getListItems = (): ReactElement[] => {
    return tabItems.map((item, index) => {
      const isLast = index === tabItems.length - 1;
      const isSelected = item.tabKey === selectedTabKey;
      const colorClass = isSelected ? 'content-primary' : 'content-body';
      const conditionalClasses = `${isLast ? '_unmargin' : 'spacing-m-b-2'} ${getClassForItem(item)} ${colorClass}`;

      return (
        <li key={`dropdown-tab-${item.tabKey}`} className="_unmargin _full-width _unpadded flexbox">
          <ClickableDiv
            className={`${conditionalClasses} ${styles.itemLayout} _full-width spacing-p-l-3 spacing-p-r-3 flex-row-align-center hover-transition-all radius-4`}
            enableFocusOutline={false}
            onClick={(e) => {
              return onTabItemClick(item.tabKey, e);
            }}
          >
            {item.preTitleContent}
            <Text val={item.text} size={TextSize.XSMALL} className={`${colorClass} ${item.textClassName}`} />
            {item.postTitleContent}
          </ClickableDiv>
        </li>
      );
    });
  };

  const getPopup = (): ReactElement => {
    return <ul className="flex-v-row _unpadded _unmargin">{getListItems()}</ul>;
  };

  return (
    <Dropdown
      selector={getMoreOptionsItem()}
      className={`${dropdownSelectorConfig?.containerClassName ?? ''} ${styles.moreOptions}`}
      popup={getPopup()}
      alignment={ALIGNMENT_TYPE.BOTTOM_START}
      popupOffset={0}
      popUpHasCustomWidth
      popupClassName={styles.popup}
    />
  );
}
