import type {ReactElement, SyntheticEvent} from 'react';
import React from 'react';
import {noop} from '@Utils/general.util';
import styles from './tab-item-content.module.scss';
import type {TabClickHandler, TabItemContentHandlers, TabItemContentStorage} from '../../tabs.types';
import {TabContentLayout, TabContentWidth, TabSliderType, TabsThemes} from '../../tabs.types';

export interface TabItemContentProps extends Omit<TabItemContentStorage, 'containerClassName'> {
  isSelected: boolean;
  contentWidthType?: TabContentWidth;
  contentLayoutType?: TabContentLayout;
  onClick?: TabClickHandler;
  sliderType?: TabSliderType;
  theme?: TabsThemes;
}

export function TabItemContent({
  text,
  tabKey,
  isSelected,
  preTitleContent,
  postTitleContent,
  href = '',
  onClick = noop,
  contentWidthType = TabContentWidth.AUTO,
  contentLayoutType = TabContentLayout.BLOCK,
  className = '',
  textClassName = 'body-s-bold',
  disabled = false,
  sliderType = TabSliderType.SLIDER_LINE,
  theme = TabsThemes.DEFAULT,
}: TabItemContentProps): ReactElement {
  const isTabValidLink = href !== '';
  const RootComponent = isTabValidLink ? 'a' : 'div';

  const clickHandler = (e: SyntheticEvent): void => {
    onClick(tabKey, e);
  };

  const getLayoutClassName = (): string => {
    switch (contentLayoutType) {
      case TabContentLayout.FLEX:
        return 'flexbox';

      case TabContentLayout.BLOCK:
      default:
        return styles.layoutBlock;
    }
  };

  const getThemeColorClasses = (): string => {
    if (!theme || theme === TabsThemes.DEFAULT) {
      return styles.themeDefault;
    }

    if (theme === TabsThemes.DARK) {
      return styles.themeDark;
    }

    if (theme === TabsThemes.PRIMARY_LIGHT) {
      return styles.themePrimaryLight;
    }

    return styles.themeDefault;
  };

  const getAllRenderClasses = (): string => {
    return `flex-center ${styles.tabItemContent} ${getLayoutClassName()} ${textClassName} ct ${disabled ? styles.disabled : ''} ${isSelected ? styles.active : ''} ${className} 
    ${contentWidthType === TabContentWidth.STRETCHED ? styles.stretched : ''} ${sliderType === TabSliderType.SLIDER_BLOCK ? styles.boxedSlider : ''} ${getThemeColorClasses()}`;
  };

  const getUserInteractionHandlers = (): TabItemContentHandlers => {
    if (disabled) {
      return {};
    }

    const handlers: TabItemContentHandlers = {};

    if (isTabValidLink) {
      handlers.href = href;
    }

    handlers.onClick = clickHandler;

    return handlers;
  };

  return (
    <RootComponent {...getUserInteractionHandlers()} className={getAllRenderClasses()}>
      {preTitleContent}
      <span>{text}</span>
      {postTitleContent}
    </RootComponent>
  );
}
