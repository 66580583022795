import type {ReactElement, SyntheticEvent} from 'react';
import React, {useEffect, useRef} from 'react';
import {isObjectEmpty} from '@Utils/object.util';
import type {TabClickHandler, TabItemContentStorage, TabItems, TabKey, TabsMoreOptionsConfig} from '@Components/tabs/tabs.types';
import {TabSeparatorIcon, TabSliderType, TabsThemes} from '@Components/tabs/tabs.types';
import {selectTab} from '@Components/tabs/tabs-reducer';
import {noop} from '@Utils/general.util';
import {IconSize} from '@Components/icon-v2/icon.types';
import {Icon} from '@Components/icon-v2';
import {TextSize} from '@Components/text';
import {MoreTabsDropdown} from '@Components/tabs/components/more-tabs-dropdown';
import styles from './tabs.module.scss';
import {TabItem} from './components/tab-item';
import {useAppDispatch} from '@/hooks';
import {useSelectedTab} from './useSelectedTab';

const DEFAULT_TAB_SLIDER_THICKNESS = '3px';
const TAB_SLIDER_KEY_DEFAULT = 'tab-slider';
const DEFAULT_SHOW_MORE_INDEX = -1;
const DEFAULT_MORE_OPTIONS_CONFIG: TabsMoreOptionsConfig = {
  iconSize: IconSize.SIZE_ICON_20,
  textSize: TextSize.SMALL,
};

export interface TabsProps {
  /**
   * The ID to give to the Tabs, this should be a unique ID as it will be used to store the selected state of the tabs within this component
   */
  id: string;
  /**
   * The Tab Items to display. See interface: TabItemContentStorage
   */
  tabItems: TabItems;
  /**
   * the tab key of the current selected tab, this will be the default when the component is initialised
   */
  selectedTab: TabKey;
  /**
   * Any separator icon to add after each tab (except the last one)
   */
  tabSeparator?: TabSeparatorIcon;
  /**
   * tabs slider key
   */
  sliderKey?: string;
  /**
   * The click handler when a tab is clicked (optional)
   */
  onTabClick?: TabClickHandler;
  /**
   * any extra classes to add
   */
  className?: string;
  /**
   * whether each tab should be of equal width or not
   */
  shouldTabsHaveEqualWidth?: boolean;
  /**
   * whether the tabs <ul> should have a border-bottom
   */
  addBorderBelowTabsContainer?: boolean;
  /**
   * Optional: A hashmap associating tabkeys to other components to display when a tabKey is the current selected tab
   */
  tabsContent?: Record<TabKey, ReactElement>;

  /**
   * prop to choose the tabs' theme
   * //TODO The dark theme is currently only implemented for the 'tabs with a block slider' variant of Tabs. Need to implement it for other variants as well
   */
  theme?: TabsThemes;

  /**
   * prop to choose what shape/type will the tabs' slider have
   */
  sliderType?: TabSliderType;
  /**
   * show the 'more' option that opens the rest of the tabs in a dropdown. The tabs will be sliced based on the index provided in this prop
   */
  moreOptionsIndex?: number;
  moreOptionsConfig?: TabsMoreOptionsConfig;
}

export function Tabs({
  id,
  selectedTab,
  tabItems,
  onTabClick = noop,
  tabSeparator = TabSeparatorIcon.NONE,
  className = '',
  sliderKey = TAB_SLIDER_KEY_DEFAULT,
  shouldTabsHaveEqualWidth = false,
  addBorderBelowTabsContainer = false,
  tabsContent = {},
  sliderType = TabSliderType.SLIDER_LINE,
  theme = TabsThemes.DEFAULT,
  moreOptionsIndex = DEFAULT_SHOW_MORE_INDEX,
  moreOptionsConfig = DEFAULT_MORE_OPTIONS_CONFIG,
}: TabsProps): ReactElement {
  const tabContainerRef = useRef(null);
  const tabSliderRef = useRef(null);
  const selectedTabKey = useSelectedTab(id, selectedTab);
  const dispatch = useAppDispatch();

  const changeSelectedTabInStore = (tabKey: TabKey): void => {
    dispatch(selectTab(id, tabKey));
  };

  const getItemFromList = (itemsList: TabItems, tabKey: TabKey): TabItemContentStorage | undefined => {
    return itemsList.find((item) => {
      return item.tabKey === tabKey;
    });
  };

  const isSelectedItemInMoreOptionsDropdown = (): boolean => {
    if (!shouldTabsBeCollapsedIntoDropdown()) {
      return false;
    }

    return getItemFromList(getItemsForMoreOptionsDropdown(), selectedTabKey) !== undefined;
  };

  const getItemsForMoreOptionsDropdown = (): TabItems => {
    return tabItems.slice(moreOptionsIndex);
  };
  const isItemSelectionDisabled = (tabKey: TabKey): boolean => {
    return getItemFromList(tabItems, tabKey)?.disableSelection === true;
  };

  const isActiveItemDisabled = (): boolean => {
    return getItemFromList(tabItems, selectedTabKey)?.disabled === true;
  };

  const onTabItemClick = (tabKey: TabKey, e: SyntheticEvent): void => {
    if (!isItemSelectionDisabled(tabKey)) {
      changeSelectedTabInStore(tabKey);
    }
    onTabClick(tabKey, e);
  };

  const shouldTabsBeCollapsedIntoDropdown = (): boolean => {
    return moreOptionsIndex >= 0 && moreOptionsIndex < tabItems.length;
  };

  const prepareTabItems = (): ReactElement[] => {
    const items: ReactElement[] = [];
    const itemsToCreate = shouldTabsBeCollapsedIntoDropdown() ? tabItems.slice(0, moreOptionsIndex) : tabItems;

    itemsToCreate.forEach((item, index) => {
      const isTabActive = selectedTabKey === item.tabKey;
      const isLastChild = index === itemsToCreate.length - 1;
      const showSeparator = !isLastChild && tabSeparator !== TabSeparatorIcon.NONE;

      items.push(
        <TabItem
          tabItem={item}
          onTabClick={onTabItemClick}
          key={`tab-item-${item.tabKey} `}
          isTabActive={isTabActive}
          isTabStretched={shouldTabsHaveEqualWidth}
          isLastTab={isLastChild}
          sliderType={sliderType}
          theme={theme}
        />
      );

      if (showSeparator) {
        items.push(
          <li
            key={`tab-separator-${item.tabKey}`}
            className={`spacing-m-t-0  ${sliderType === TabSliderType.SLIDER_BLOCK ? '' : 'spacing-m-b-2 spacing-m-r-1'} spacing-m-l-1 _unpadded flexbox`}
          >
            <Icon icon={tabSeparator} size={IconSize.SIZE_ICON_16} />
          </li>
        );
      }

      if (item.extraElementAfterTab) {
        items.push(
          <li key={`post-tab-content-${item.tabKey}`} className={`flexbox ${item.extraElementContainerClassName ?? ''}`}>
            {item.extraElementAfterTab}
          </li>
        );
      }
    });

    return items;
  };

  const getMoreOptionsDropdown = (): ReactElement | null => {
    if (!shouldTabsBeCollapsedIntoDropdown()) {
      return null;
    }

    return (
      <li className={`flexbox _unmargin _unpadded js-more-tab-options ${isSelectedItemInMoreOptionsDropdown() ? 'js-more-options-selected' : ''}`}>
        <MoreTabsDropdown tabItems={getItemsForMoreOptionsDropdown()} selectedTabKey={selectedTabKey} dropdownSelectorConfig={moreOptionsConfig} onTabItemClick={onTabItemClick} />
      </li>
    );
  };

  useEffect(() => {
    calculateAndSetTabSliderStyles(tabContainerRef, tabSliderRef, sliderType);
  }, [selectedTabKey, shouldTabsHaveEqualWidth, tabSeparator, sliderType, sliderKey, moreOptionsIndex]);

  useEffect(() => {
    const observer = new ResizeObserver((): void => {
      calculateAndSetTabSliderStyles(tabContainerRef, tabSliderRef, sliderType);
    });

    if (tabContainerRef.current) {
      observer.observe(tabContainerRef.current);
    }

    return (): void => {
      observer.disconnect();
    };
  }, [tabContainerRef.current]);

  const getContentForSelectedTab = (): ReactElement | null => {
    if (isObjectEmpty(tabsContent)) {
      return null;
    }

    return tabsContent[selectedTabKey];
  };

  const getBorderClasses = (): string => {
    return addBorderBelowTabsContainer ? 'border-s-standard _no-border-top _no-border-left _no-border-right' : '';
  };

  const getThemeColorClasses = (): string => {
    if (!theme || theme === TabsThemes.DEFAULT) {
      return styles.themeDefault;
    }

    if (theme === TabsThemes.DARK) {
      return styles.themeDark;
    }

    if (theme === TabsThemes.PRIMARY_LIGHT) {
      return styles.themePrimaryLight;
    }

    if (theme === TabsThemes.GRADIENT) {
      return styles.gradient;
    }

    return styles.themeDefault;
  };

  const getClassesForTabsContainer = (): string => {
    return `${styles.tabsContainer} ${sliderType === TabSliderType.SLIDER_BLOCK ? styles.boxedSlider : ''} ${getBorderClasses()} ${getThemeColorClasses()} flex-row-align-center ${
      shouldTabsHaveEqualWidth ? styles.fullWidth : ''
    } ${className}`;
  };

  const getClassesForTabSlider = (): string => {
    return `${styles.tabSlider} ${sliderType === TabSliderType.SLIDER_BLOCK ? styles.boxedSlider : ''} ${isActiveItemDisabled() ? styles.disabled : ''} _unmargin ${
      sliderType === TabSliderType.SLIDER_BLOCK ? 'spacing-p-1' : '_unpadded'
    }  ${getThemeColorClasses()}`;
  };

  const getTabList = (): ReactElement => {
    return (
      <ul className={getClassesForTabsContainer()} ref={tabContainerRef}>
        {prepareTabItems()}
        {getMoreOptionsDropdown()}
        <li key={sliderKey} className={getClassesForTabSlider()} ref={tabSliderRef} />
      </ul>
    );
  };

  return (
    <>
      <div className={sliderType === TabSliderType.SLIDER_BLOCK ? 'flexbox' : ''}>{getTabList()}</div>
      {getContentForSelectedTab()}
    </>
  );
}

/**
 * calculates and sets the position of the tab slider based on the currently active Tab
 */
const calculateAndSetTabSliderStyles = (tabBarRef: React.RefObject<HTMLElement>, tabSliderRef: React.RefObject<HTMLElement>, sliderType: TabSliderType): void => {
  if (!tabBarRef.current || !tabSliderRef.current) {
    return;
  }

  const tabBarElement = tabBarRef.current;
  const tabSliderElement = tabSliderRef.current;
  const activeTab: HTMLElement | null = tabBarElement.querySelector('.js-tab-active') ?? tabSliderElement;
  const moreTabOptions: HTMLElement | null = tabBarElement.querySelector('.js-more-tab-options');
  const isMoreOptionsTabSelected = moreTabOptions?.classList?.contains('js-more-options-selected') === true;

  if (isMoreOptionsTabSelected) {
    tabSliderElement.attributeStyleMap.clear();
    tabSliderElement.style.left = `${moreTabOptions?.offsetLeft ?? 0}px`;
    return;
  }

  tabSliderElement.style.bottom = '0';
  tabSliderElement.style.left = `${activeTab.offsetLeft}px`;
  tabSliderElement.style.width = `${activeTab.offsetWidth}px`;

  if (sliderType === TabSliderType.SLIDER_BLOCK) {
    const activeTabHeight = activeTab.offsetHeight;
    const boxSliderOffset = 8;
    tabSliderElement.style.height = `${activeTabHeight > boxSliderOffset ? activeTabHeight - boxSliderOffset : DEFAULT_TAB_SLIDER_THICKNESS}px`;
    tabSliderElement.style.top = `${boxSliderOffset / 2}px`;
  } else if (sliderType === TabSliderType.SLIDER_LINE) {
    tabSliderElement.style.height = DEFAULT_TAB_SLIDER_THICKNESS;
    tabSliderElement.style.top = '';
  }
};
