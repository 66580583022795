import type {ReactElement} from 'react';
import React from 'react';
import {TabItemContent} from '@Components/tabs/components/tab-item-content';
import type {TabClickHandler, TabItemContentStorage} from '@Components/tabs/tabs.types';
import { TabContentLayout, TabContentWidth, TabSliderType, TabsThemes} from '@Components/tabs/tabs.types';
import {Icon} from '@Components/icon-v2';
import {IconShape, IconSize} from '@Components/icon-v2/icon.types';
import {noop} from '@Utils/general.util';
import styles from './tab-item.module.scss';

export interface TabItemProps {
  tabItem: TabItemContentStorage;
  isTabActive: boolean;
  isTabStretched: boolean;
  isLastTab: boolean;
  onTabClick?: TabClickHandler;
  sliderType?: TabSliderType;
  theme?: TabsThemes;
}

export function TabItem({
  tabItem,
  isTabActive,
  isTabStretched,
  isLastTab,
  onTabClick = noop,
  sliderType = TabSliderType.SLIDER_LINE,
  theme = TabsThemes.DEFAULT,
}: TabItemProps): ReactElement {
  const shouldTabContainerHaveNoMargin = (): boolean => {
    return isTabStretched || isLastTab || sliderType === TabSliderType.SLIDER_BLOCK;
  };

  const tabContentWidthType = isTabStretched ? TabContentWidth.STRETCHED : TabContentWidth.AUTO;
  const tabPostTitleContent = tabItem.showPremiumCrown ? (
    <Icon icon="icon-crown" shape={IconShape.NONE} size={IconSize.SIZE_ICON_20} className="color-premium spacing-m-r-1 spacing-m-l-1" />
  ) : (
    tabItem.postTitleContent
  );
  const tabItemClassName = `${tabItem.className ?? ''} ${tabPostTitleContent ? 'flex-center' : ''}`;
  const tabItemLayout = tabPostTitleContent || !!tabItem.preTitleContent ? TabContentLayout.FLEX : TabContentLayout.BLOCK;

  const getRootListItemClasses = (): string => {
    const spacingClasses = `${shouldTabContainerHaveNoMargin() ? 'spacing-m-0' : styles.marginRight} _unpadded`;
    return `${isTabActive ? 'js-tab-active' : ''} ${isTabStretched ? 'flex-1' : ''} ${spacingClasses} ${tabItem.containerClassName ?? ''}`;
  };

  return (
    <React.Fragment key={`tab-fragment-${tabItem.tabKey}`}>
      <li key={`tab-li-${tabItem.tabKey}`} className={getRootListItemClasses()}>
        <TabItemContent
          key={`tab-content-${tabItem.tabKey}`}
          {...tabItem}
          onClick={onTabClick}
          isSelected={isTabActive}
          className={tabItemClassName}
          postTitleContent={tabPostTitleContent}
          contentWidthType={tabContentWidthType}
          contentLayoutType={tabItemLayout}
          sliderType={sliderType}
          theme={theme}
        />
      </li>
    </React.Fragment>
  );
}
