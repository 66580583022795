import type { PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import type {TabKey} from '@Components/tabs/tabs.types';

interface SelectedTabInfo {
  tabsId: string;
  selectedTabKey: TabKey;
}

interface TabsState {
  selectedTabsHashmap: Record<string, TabKey>;
}

const initialState: TabsState = {
  selectedTabsHashmap: {},
};

const tabsSlice = createSlice({
  name: 'reactTabs',
  initialState,
  reducers: {
    selectTab: {
      reducer: (state, action: PayloadAction<SelectedTabInfo>): void => {
        state.selectedTabsHashmap[action.payload.tabsId] = action.payload.selectedTabKey;
      },

      prepare: (tabsId: string, selectedTabKey: TabKey) => {
        return {payload: {tabsId, selectedTabKey}};
      },
    },

    deleteTabStateForId: (state, action: PayloadAction<string>): void => {
      delete state.selectedTabsHashmap[action.payload];
    },
  },
});

export const {selectTab, deleteTabStateForId} = tabsSlice.actions;
export const tabsReducer = tabsSlice.reducer;
