import type {TabKey} from './tabs.types';
import {useAppSelector} from '@/hooks';

/**
 * a reusable hook that fetches the selected tab given a Tab groups ID. If no selected tab is found, the fallback tabKey provided is returned
 */
export const useSelectedTab = (tabsId: string, fallbackTabKey: TabKey): TabKey => {
  return useAppSelector(({tabs}) => {
    return tabs.selectedTabsHashmap[tabsId] ?? fallbackTabKey;
  });
};
